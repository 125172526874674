
import { useQuery } from '@tanstack/react-query';
import homeServiceInstance from '../services/home-service';

function useGetNoteList(data) {
  return useQuery({
    queryKey: [homeServiceInstance.queryKeys.getNoteList + data?.orderBy + data?.authorId],
    queryFn: () => homeServiceInstance.getNoteList(data),
  });
}

export default useGetNoteList;