import React, { useState, useRef, useEffect } from 'react';
import './App.css';

const VideoPlayer = ({url}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [duration, setDuration] = useState(0);
  const [, setCurrentTime] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      setDuration(videoElement?.duration || 0);
    };

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, []);

  const handlePlayPause = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started successfully
          })
          .catch(error => {
            console.error("Playback error:", error);
          });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (e) => {
    if (!videoRef.current || !duration) return;

    const value = parseFloat(e.target.value);
    const time = (value * duration) / 100;
    videoRef.current.currentTime = time;
    setProgress(value);
    setCurrentTime(time);
  };

  const handleTimeUpdate = () => {
    if (!videoRef.current) return;

    const current = videoRef.current.currentTime;
    const percent = (current / videoRef.current.duration) * 100;
    setProgress(percent);
    setCurrentTime(current);
  };

  const handleFullscreen = () => {
    if (!videoRef.current) return;

    if (!isFullscreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleMute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <div className="relative aspect-video bg-black rounded-lg overflow-hidden group">
      <video
        ref={videoRef}
        className="w-full h-full object-cover"
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
        playsInline
        onClick={handlePlayPause}
      >
        <source src={!!url ? url : "https://figfig-cdn.rovex.io/v1.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Center play button */}
      {!isPlaying && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/30">
          <button
            onClick={handlePlayPause}
            className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 bg-black/60 rounded-full flex items-center justify-center transition-all"
          >
            <svg className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 text-white" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        </div>
      )}

      {/* Bottom controls */}
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/60 to-transparent">
        <div className="px-2 sm:px-3 md:px-4 pb-2">
          <div className="flex items-center justify-between gap-2 sm:gap-3 mt-2">
            {/* Left side controls */}
            <div className="flex items-center gap-2 sm:gap-3 flex-1">
              <button
                onClick={handlePlayPause}
                className="w-8 h-8 sm:w-8 sm:h-8 bg-black/60 rounded-full flex items-center justify-center transition-all"
              >
                {isPlaying ? (
                  <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8 5v14l11-7z" />
                  </svg>
                )}
              </button>

              <button
                onClick={handleMute}
                className="w-8 h-8 sm:w-8 sm:h-8 bg-black/60 rounded-full flex items-center justify-center transition-all"
              >
                {!isMuted ? (
                  <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M5 15V9h4l5-5v16l-5-5H5z" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M5 15V9h4l5-5v16l-5-5H5zm11.36-3.64l2.12-2.12 1.41 1.41-2.12 2.12 2.12 2.12-1.41 1.41-2.12-2.12-2.12 2.12-1.41-1.41 2.12-2.12-2.12-2.12 1.41-1.41 2.12 2.12z" />
                  </svg>
                )}
              </button>

              <div className="flex-1">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={progress}
                  onChange={handleProgress}
                  className="w-full h-1 rounded-full appearance-none cursor-pointer bg-white/30"
                  style={{
                    background: `linear-gradient(to right, white ${progress}%, rgba(255,255,255,0.3) ${progress}%)`,
                    WebkitAppearance: 'none'
                  }}
                />
              </div>
            </div>

            {/* Right side controls */}
            <button
              onClick={handleFullscreen}
              className="w-8 h-8 sm:w-8 sm:h-8 bg-black/60 rounded-full flex items-center justify-center transition-all"
            >
              {isFullscreen ? (
                <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" />
                </svg>
              ) : (
                <svg className="w-6 h-6 sm:w-4 sm:h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;