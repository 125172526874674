import './../App.css';
import React from 'react';

const Skeleton = () => {
    return (
      <div className="max-w-md mx-auto min-h-screen bg-[#F2F4F9] lg:max-w-4xl animate-pulse">
        {/* Header Skeleton */}
        <header className="flex justify-between items-center px-4 py-3 bg-[#F2F4F9]">
          <div className="w-4 h-4 bg-gray-200 rounded"></div>
          <div className="w-5 h-5 bg-gray-200 rounded"></div>
        </header>
  
        {/* Main Content Skeleton */}
        <div className="p-4 space-y-4">
          {/* Title Section Skeleton */}
          <div className="bg-white rounded-2xl p-4 space-y-4">
            {/* Title */}
            <div className="h-6 bg-gray-200 rounded-lg w-3/4"></div>
  
            {/* Stats */}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="h-4 bg-gray-200 rounded w-20"></div>
                <div className="h-4 bg-gray-200 rounded w-16"></div>
              </div>
              <div className="h-4 bg-gray-200 rounded w-24"></div>
            </div>
  
            {/* Video Player Skeleton */}
            <div className="aspect-video bg-gray-200 rounded-lg w-full"></div>
          </div>
  
          {/* More Videos Section Skeleton */}
          <div className="bg-white rounded-2xl p-4">
            {/* Section Header */}
            <div className="flex justify-between items-center mb-4">
              <div className="h-5 bg-gray-200 rounded w-20"></div>
              <div className="h-5 bg-gray-200 rounded w-16"></div>
            </div>
  
            {/* Video Grid */}
            <div className="grid grid-cols-3 gap-3">
              {[1, 2, 3].map((item) => (
                <div key={item} className="space-y-2">
                  <div className="aspect-video bg-gray-200 rounded-lg"></div>
                  <div className="space-y-1">
                    <div className="h-3 bg-gray-200 rounded w-full"></div>
                    <div className="h-3 bg-gray-200 rounded w-2/3"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        {/* Download Banner Skeleton */}
        <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black to-black/60 p-4 pt-12">
          <div className="h-4 bg-gray-200/20 rounded w-3/4 mx-auto mb-3"></div>
          <div className="h-12 bg-gray-200/20 rounded-lg w-full"></div>
        </div>
      </div>
    );
  };

  export default Skeleton