export function formatView(amount) {
    const crore = 10000000;
    const lakh = 100000;
  
    if (amount >= crore) {
        return `${(amount / crore)?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} Cr`;
    } else if (amount >= lakh) {
        return `${(amount / lakh)?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} L`;
    } else if (amount >= 1000) {
        return `${(amount / 1000)?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} K`;
    } else {
        return amount?.toString(); // return as-is for amounts less than 1000
    }
  }

  export const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}.${month}.${day}`;
  };

  export const formatAmount = (amount) => {
    const amountStr = amount?.toString();
    if (amountStr?.length <= 6) return amountStr;
    return amountStr?.slice(0, 6) + '...';
  };