import React from 'react';

const Experience = ({ isOpen, onClose, onOpenApp }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        {/* Modal Container */}
        <div className="bg-white rounded-3xl w-full max-w-sm overflow-hidden">
          {/* Modal Content */}
          <div className="p-6 space-y-6">
            <h2 className="text-xl text-center font-medium text-gray-900">
              Open the APP for a better viewing experience.
            </h2>
  
            {/* OK Button */}
            <button
              onClick={onOpenApp}
              className="w-full py-4 px-4 bg-[#F47B20] hover:bg-[#e06b15] text-white text-lg font-medium rounded-xl transition-colors"
            >
              OK
            </button>
  
            {/* Cancel Button */}
            <button
              onClick={onClose}
              className="w-full py-4 px-4 bg-[#C3CBCE] hover:bg-gray-300 text-white text-lg font-medium rounded-xl transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };


  export default Experience