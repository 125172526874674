
import { fetcher } from "../utils/fetcher";

class HomeService {
  queryKeys = {
    getNoteList: 'getNoteList',
    getNoteDetail: 'getNoteDetail'
  };

  getNoteList = async (data) => {

    const payload = {
      page: 1,
      size: 10,
      order_by : data?.orderBy,
      order : 'desc',
      author : data?.authorId
    }
    return fetcher({
      url: '/api/v1/note/list',
      method: 'POST',
      data : payload
    });
  }

  getNoteDetail = async (data)=> {
    
    const payload = {
      note_id:data?.noteId,
      inviter:data?.inviterId
    }
    return fetcher({
      url: '/api/v1/note/detail',
      method: 'POST',
      data : payload
    });
  }
}



const homeServiceInstance = new HomeService();
export default homeServiceInstance;