
import { useQuery } from '@tanstack/react-query';
import homeServiceInstance from '../services/home-service';

function useGetNoteDetail(data) {
  return useQuery({
    queryKey: [homeServiceInstance.queryKeys.getNoteDetail + data?.noteId + data?.inviterId],
    queryFn: () => homeServiceInstance.getNoteDetail(data),
  });
}

export default useGetNoteDetail;